import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.js';

export default function Internships() {
    const [internships, setInternships] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchInternships = async () => {
            const querySnapshot = await getDocs(collection(db, 'internships')); // Grabs documents from collection (internships)
            const internshipsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInternships(internshipsData);
        };

        fetchInternships();
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredInternships = internships.filter(internship => 
        internship.company.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <main className="flex flex-col font-outfit min-h-screen bg-neutral-900">
            <div className="bg-neutral-900">
                <Navbar />
                <section id="internships" className="w-full flex flex-col items-center bg-neutral-900 p-4">
                    <div className="w-full flex-grow flex flex-col items-center mt-10">
                        <div className="w-full lg:w-9/12 flex flex-col bg-zinc-800 border-[1px] border-zinc-700 rounded-xl p-4">
                            <div className="w-full mb-4">
                                <input 
                                    type="text" 
                                    placeholder="Search for specific internship" 
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="outline-none h-12 w-full p-2 rounded-2xl text-lg font-medium" 
                                />
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                                {filteredInternships.map((internship) => (
                                    <div key={internship.id} className="bg-white w-full rounded-2xl p-4 flex flex-col justify-between h-auto overflow-hidden">
                                        <div>
                                            <h1 className="text-emerald-500 text-xl sm:text-3xl font-semibold truncate">{internship.company}</h1>
                                        </div>
                                        <div className="flex-grow p-2">
                                            <p className="text-base lg:text-lg font-base text-slate-600 overflow-hidden text-ellipsis">{internship.desc}</p>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="bg-green-600 text-white w-full lg:w-80 h-24 text-sm lg:text-base flex flex-col items-center justify-center rounded-xl hover:scale-[.98] transition-all ease-in-out duration-300 overflow-hidden">
                                                <div className="truncate">{internship.field}</div>
                                                <div className="truncate">{internship.city}</div>
                                                <div className="truncate">{internship.number}</div>
                                                <a href={internship.website} className="truncate">{internship.website}</a>
                                            </div>
                                        </div>
                                    </div>         
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="mt-10 w-full">
                <Footer />
            </div>
        </main>
    );
}
