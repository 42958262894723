import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore'; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBkE63FvsjJ2YoqCA1J45lZca5o8Fe3hg",
  authDomain: "interntracker-9ef15.firebaseapp.com",
  projectId: "interntracker-9ef15",
  storageBucket: "interntracker-9ef15.appspot.com",
  messagingSenderId: "159421385308",
  appId: "1:159421385308:web:071e4a5e10afab57183499",
  measurementId: "G-GTMSPY8T6H"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore();
export {db};


