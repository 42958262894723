import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (name, key) => {
    if (name === 'dynamite' && key === 'fdbX2f-gpEUrr-NKZ7kv-wt1VDP-QQZ1QY-F1us4A') {
      setIsAuthenticated(true);
      return true; // Indicate successful login
    } else {
      alert('Invalid credentials');
      return false; // Indicate failed login
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
