import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase.js';

const AddScholarship = () => {
    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [requirements, setRequirements] = useState('');
    const [essay, setEssay] = useState('');
    const [reward, setReward] = useState('');
    const [desc, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'scholarships'), { //Adds document to internship collection
                name,
                website, 
                requirements, 
                essay, 
                reward,
                desc
            });

            setName('');
            setWebsite('');
            setRequirements('');
            setEssay('');
            setReward('');
            setDescription('');
        } catch(error) {
            console.error("Error loading document: ", error);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col p-4 bg-zinc-800 border-[1px] border-zinc-700 rounded-xl w-[30rem] ">
            <input 
                type="text" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                placeholder="Name" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={website} 
                onChange={(e) => setWebsite(e.target.value)} 
                placeholder="Website" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={requirements} 
                onChange={(e) => setRequirements(e.target.value)} 
                placeholder="Requirements" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={essay} 
                onChange={(e) => setEssay(e.target.value)} 
                placeholder="Essay" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={reward} 
                onChange={(e) => setReward(e.target.value)} 
                placeholder="Reward" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={desc} 
                onChange={(e) => setDescription(e.target.value)} 
                placeholder="Description" 
                required 
                className="mb-2 p-2 rounded"
            />
            <button type="submit" className="bg-emerald-600 text-white p-2 rounded">Add Scholarship</button>
        </form>
    );
};

export default AddScholarship;