import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

document.oncontextmenu = () => {
  return false;
}

document.onkeydown = e => {
  if (e.key == "F12") {
    return false;
  }

  if (e.ctrlKey && e.key == "u") {
    return false;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

