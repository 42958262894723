import Navbar from '../components/Navbar.js';
import AddInternship from '../components/AddInternships.js';
import AddScholarship from '../components/AddScholarships.js';

export default function Admin() {
    return (
        <main className="flex flex-col font-outfit min-h-screen bg-neutral-900">
            <div className="bg-neutral-900">
                <Navbar />
                <section id="admin" className="w-screen flex flex-col items-center bg-neutral-900">
                    <div className='w-full flex items-center justify-evenly mt-10'>
                        <AddInternship></AddInternship>
                        <AddScholarship></AddScholarship>
                    </div>

                </section>
            </div>
        </main>
    );
}
