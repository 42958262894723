import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

export default function Home() {
    return (
        <main className="flex flex-col font-outfit min-h-screen">
            <div className="bg-neutral-900">
                <Navbar />
                <section id="home" className="w-full flex flex-col items-center bg-neutral-900">
                    <div className="flex-grow flex flex-col justify-center items-center">
                        <div className="h-auto flex flex-col items-center justify-center p-5 md:p-10 lg:h-[40rem]">
                            <h1 className="my-5 font-semibold text-4xl md:text-7xl lg:text-9xl text-transparent bg-clip-text bg-gradient-to-b from-emerald-200 to-emerald-500">InternTracker</h1>
                            <h3 className="font-semibold text-lg md:text-xl text-gray-100 text-center">The place to search for <span className="text-emerald-500">internships and scholarships.</span></h3>
                            <h3 className="font-light text-md md:text-lg text-gray-100 p-2 w-full md:w-96 text-center">InternTracker provides resources and information to find internships and scholarships of your need.</h3>
                        </div>
                    </div>

                    <div className="flex flex-col items-center bg-neutral-900 p-5">
                        <h3 className="text-xl md:text-2xl font-semibold p-2 text-emerald-500 text-center">Search for internships and scholarships of your desire.</h3>
                        <a href="/internships" className="my-5 w-32 md:w-40 h-10 bg-emerald-800 rounded-lg shadow-xl font-semibold border-[1px] border-emerald-700 outline-none text-gray-300 hover:bg-lime-600 hover:border-lime-600 hover:scale-[.99] transition-all ease-in-out duration-300 flex flex-col items-center justify-center cursor-pointer">Search Now</a>
                    </div>

                    <div className="my-10 w-full lg:w-5/6 flex flex-col lg:flex-row justify-between items-center p-5">
                        <div className="border-l-2 border-zinc-700 w-full lg:w-1/2 p-2">
                            <h1 className="text-3xl md:text-4xl lg:text-5xl text-gray-100 font-semibold px-2">Browse For Internships</h1>
                            <p className="text-lg md:text-xl lg:text-2xl text-gray-100 font-light p-2">
                                Search for your desired internship. With InternTracker, internships around the US are simplified for you to know whether or not it's good for you.
                                InternTracker shows the contact, website, location, job field, and more about internships.
                            </p>
                        </div>
                        <div className="mt-10 lg:mt-0">
                            <img src="components/conversation.svg" alt="Conversation" className="h-auto w-full lg:h-[45rem] lg:w-[40rem]" />
                        </div>
                    </div>

                    <div className="my-10 w-full lg:w-5/6 flex flex-col lg:flex-row justify-between items-center p-5">
                        <div className="mt-10 lg:mt-0">
                            <img src="components/office.svg" alt="Office" className="h-auto w-full lg:h-[45rem] lg:w-[40rem]" />
                        </div>
                        <div className="border-r-2 border-emerald-500 w-full lg:w-1/2 p-2">
                            <h1 className="text-3xl md:text-4xl lg:text-5xl text-emerald-500 font-semibold px-2 text-right">Search For Scholarships</h1>
                            <p className="text-lg md:text-xl lg:text-2xl text-emerald-500 font-light p-2 text-right">
                                Browse for any scholarship program in the country. InternTracker makes it simple and concise for students to track scholarship requirements, websites, and resources. Searching for scholarships makes it straightforward.
                            </p>
                        </div>
                    </div>

                    <div className="bg-neutral-900 w-full p-5">
                        <div className="flex flex-col items-center my-5">
                            <h1 className="text-emerald-500 text-3xl md:text-4xl font-semibold text-center">At InternTracker, we make it easy to find scholarships and internships for you.</h1>
                        </div>

                        <div className="flex flex-col items-center my-10">
                            <h1 className="text-gray-100 text-3xl md:text-4xl font-semibold text-center">Ready to start browsing?</h1>
                            <div className="flex flex-col md:flex-row items-center justify-evenly w-full md:w-[30rem]">
                                <a href="/internships" className="my-5 w-32 md:w-40 h-10 bg-emerald-800 rounded-lg shadow-xl font-semibold border-[1px] border-emerald-700 outline-none text-gray-300 hover:bg-lime-600 hover:border-lime-600 hover:scale-[.99] transition-all ease-in-out duration-300 flex flex-col items-center justify-center cursor-pointer">Internships</a>
                                <a href="/scholarships" className="my-5 w-32 md:w-40 h-10 bg-emerald-800 rounded-lg shadow-xl font-semibold border-[1px] border-emerald-700 outline-none text-gray-300 hover:bg-lime-600 hover:border-lime-600 hover:scale-[.99] transition-all ease-in-out duration-300 flex flex-col items-center justify-center cursor-pointer">Scholarships</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </main>
    );
}
