import { useState } from 'react';

export default function Navbar() {
    return (
        <nav className="justify-center bg-zinc-800 border-[1px] border-zinc-700 text-white flex lg:justify-between items-center gap-4 px-5 w-full h-20">
            <a href="/" className="hidden text-2xl h-full lg:flex items-center text-white hover:text-emerald-500 active:duration-150 transition-all ease-in-out duration-300">Intern<span className="text-emerald-500">Tracker</span></a>
            <ul className="sm:flex sm:items-center h-full flex">
                <li>
                    <a href="/home" className="no-underline h-full flex items-center p-1 sm:text-base sm:mx-5 text-white hover:text-emerald-500 active:duration-150 hover:scale-95 transition-all ease-in-out duration-300">Home</a>
                </li>
                <li>
                    <a href="/about" className="no-underline h-full flex items-center p-1 sm:text-base sm:mx-5 text-white hover:text-emerald-500 active:duration-150 hover:scale-95 transition-all ease-in-out duration-300">About</a>
                </li>
                <li>
                    <a href="/internships" className="no-underline h-full flex items-center p-1 sm:text-base sm:mx-5 text-white hover:text-emerald-500 active:duration-150 hover:scale-95 transition-all ease-in-out duration-300">Internships</a>
                </li>
                <li>
                    <a href="/scholarships" className="no-underline h-full flex items-center p-1 sm:text-base sm:mx-5 text-white hover:text-emerald-500 active:duration-150 hover:scale-95 transition-all ease-in-out duration-300">Scholarships</a>
                </li>
            </ul>
        </nav>
    );
}
