import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.js';

export default function Scholarships() {
    const [scholarships, setScholarships] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchScholarships = async () => {
            const querySnapshot = await getDocs(collection(db, 'scholarships')); // Grabs documents from collection (internships)
            const scholarshipsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setScholarships(scholarshipsData);
        };

        fetchScholarships();
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredScholarships = scholarships.filter(scholarship => 
        scholarship.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <main className="flex flex-col font-outfit min-h-screen bg-neutral-900">
            <div className="bg-neutral-900">
                <Navbar />
                <section id="scholarships" className="w-full flex flex-col items-center bg-neutral-900 p-4">
                    <div className="w-full flex-grow flex flex-col items-center mt-10">
                        <div className="w-full lg:w-9/12 flex flex-col bg-zinc-800 border-[1px] border-zinc-700 rounded-xl p-4">
                            <div className="w-full mb-4">
                                <input 
                                    type="text" 
                                    placeholder="Search for specific scholarship" 
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="outline-none h-12 w-full p-2 rounded-2xl text-lg font-medium" 
                                />
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                                {filteredScholarships.map((scholarship) => (
                                    <div key={scholarship.id} className="bg-white w-full rounded-2xl p-4 flex flex-col justify-between h-auto">
                                        <div>
                                            <h1 className="text-emerald-500 text-2xl lg:text-3xl font-semibold">{scholarship.name}</h1>
                                        </div>
                                        <div className="flex-grow p-2">
                                            <h2 className="text-xl lg:text-2xl font-medium text-emerald-500">{scholarship.requirements}</h2>
                                            <p className="text-base lg:text-lg font-base py-2 text-slate-600">{scholarship.desc}</p>
                                        </div>
                                        <div className="flex flex-col sm:flex-row items-center justify-evenly gap-2">
                                            <a href={scholarship.website} target="_blank" rel="noreferrer" className="bg-green-600 text-white w-full sm:w-44 h-12 text-lg flex items-center justify-center rounded-xl hover:scale-[.98] transition-all ease-in-out duration-300">Enter Here</a>
                                            <label className="bg-green-600 text-white w-full sm:w-44 h-12 text-lg flex items-center justify-center rounded-xl hover:scale-[.98] transition-all ease-in-out duration-300">Essay: {scholarship.essay}</label>
                                            <label className="bg-green-600 text-white w-full sm:w-44 h-12 text-lg flex items-center justify-center rounded-xl hover:scale-[.98] transition-all ease-in-out duration-300">{scholarship.reward} Reward</label>
                                        </div>
                                    </div>                                    
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="mt-10 w-full">
                <Footer />
            </div>
        </main>
    );
}
