import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Internships from './pages/Internships';
import Scholarships from './pages/Scholarships';
import Admin from './pages/Admin';
import Login from './pages/Login';
import InternRequest from './pages/InternRequest';
import ScholarRequest from './pages/ScholarRequest';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './pages/AuthContext';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/internships" element={<Internships />} />
          <Route path="/scholarships" element={<Scholarships />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
          <Route path="/irequest" element={<InternRequest />} />
          <Route path="/srequest" element={<ScholarRequest />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Login />;
}

export default App;
