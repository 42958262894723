import React from 'react';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

export default function InternRequest() {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "52c697f7-98f1-4b19-9fb8-47a996ae243d");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <main className="flex flex-col font-outfit bg-neutral-900">
            <Navbar />
            <div className="flex flex-col items-center justify-center min-h-screen bg-neutral-900 px-4 py-8">
                <div className="text-center mb-8">
                    <h1 className="text-3xl md:text-5xl text-emerald-500 font-semibold mb-4">Submit A Scholarship Program</h1>
                    <p className="text-base md:text-xl text-gray-100 font-semibold">If you want to submit a scholarship to be added, it will be looked over and added if confirmed.</p>
                </div>

                <form onSubmit={onSubmit} className="flex flex-col items-center p-4 bg-zinc-800 border-[1px] border-zinc-700 rounded-xl w-full max-w-lg">
                    <input 
                        type="text" 
                        name="program" 
                        required 
                        className="h-10 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Program Name"
                    />
                    <input 
                        type="text" 
                        name="website" 
                        required 
                        className="h-10 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Website Link"
                    />
                    <input 
                        type="text" 
                        name="requirement" 
                        required 
                        className="h-10 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Requirements"
                    />
                    <input 
                        type="text" 
                        name="essay" 
                        required 
                        className="h-10 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Essay Required (Y/N)"
                    />
                    <input 
                        type="text" 
                        name="reward" 
                        required 
                        className="h-10 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Rewards"
                    />
                    <textarea 
                        name="description" 
                        required 
                        className="h-24 w-full mb-4 outline-none rounded-lg p-2 text-gray-800 shadow-2xl" 
                        placeholder="Description"
                    ></textarea>
                    <button 
                        type="submit" 
                        className="w-full h-12 bg-emerald-800 rounded-lg shadow-xl border-[1px] border-emerald-700 outline-none text-gray-300 hover:bg-lime-600 hover:border-lime-600 hover:scale-[.99] transition-all ease-in-out duration-300 flex items-center justify-center cursor-pointer"
                    >
                        Submit Request
                    </button>
                </form>
            </div>
            <Footer />
        </main>
    );
}
