export default function Footer() {
    return (
        <footer className="bg-zinc-900 w-full p-5 flex flex-col items-center">
            <div className="w-full lg:w-3/6 h-full">
                <div className="flex flex-col lg:flex-row justify-around gap-5 lg:gap-0">
                    <div className="flex flex-col items-center">
                        <h1 className="text-gray-400 uppercase font-bold text-2xl">Links</h1>
                        <a href="/" className="text-gray-400 text-md">Home</a>
                        <a href="/about" className="text-gray-400 text-md">About</a>
                        <a href="/internships" className="text-gray-400 text-md">Internships</a>
                        <a href="/scholarships" className="text-gray-400 text-md">Scholarships</a>
                    </div>

                    <div className="flex flex-col items-center">
                        <h1 className="text-gray-400 uppercase font-bold text-2xl">Submissions</h1>
                        <a href="/irequest" className="text-gray-400 text-md">Submit Internship</a>
                        <a href="/srequest" className="text-gray-400 text-md">Submit Scholarship</a>
                    </div>
                    
                    <div className="flex flex-col items-center">
                        <h1 className="text-gray-400 uppercase font-bold text-2xl">Resources</h1>
                        <a href="/internships" className="text-gray-400 text-md">Internships</a>
                        <a href="/scholarships" className="text-gray-400 text-md">Scholarships</a>
                    </div>
                </div>
            </div>

            <div className="w-full  flex items-center justify-center mt-5 lg:mt-0">
                <a href="https://fernandosoto.xyz" target="_blank" className="text-gray-400 text-sm">Made By <span className="text-emerald-500">Fernando Soto Briceno</span></a>
            </div>
      </footer>
    );
}
