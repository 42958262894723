import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

export default function About() {
    return (
        <main className="flex flex-col font-outfit min-h-screen">
            <div className="bg-neutral-900">
                <Navbar />
                <section id="about" className="w-full min-h-screen flex flex-col items-center bg-neutral-900">
                    <div className="flex-grow flex flex-col justify-center items-center w-full">
                        <div className="min-h-screen w-full lg:w-1/2 grid grid-rows-4 gap-4 items-center mt-10 px-5 lg:px-0">
                            <div className="flex flex-col text-left border-l-2 px-2 border-emerald-500">
                                <h1 className="text-emerald-500 text-2xl lg:text-4xl font-semibold">The Goal Of InternTracker</h1>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    InternTracker's purpose is to help students —whether in high school or college— succeed in academics by giving the necessary resources of internships and scholarships around the United States.
                                    It is with the best intent that students should excel in their desired field, as many high schoolers search for internships and scholarships.
                                    With InternTracker, we make it easy for students to browse for internships and scholarships which can be national or local.
                                    Making it simple for students, InternTracker's database of internships and scholarships will be updated daily, and users are able to submit internships or scholarships.
                                </p>
                            </div>
                            <div className="flex flex-col text-left justify-evenly border-l-2 px-2 border-emerald-500">
                                <h1 className="text-emerald-500 text-2xl lg:text-4xl font-semibold">How InternTracker Works</h1>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    InternTracker contains a database of internships and scholarships across the United States. Both have separate pages to make it easy to search for one or the other.
                                </p>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    When searching for internships, each record will hold the company name, the description of the company, the location, the contact, and the field the company specializes in.
                                </p>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    Similarly with scholarships, each scholarship record will show the programs name, website, requirements, description, and reward.
                                </p>
                            </div>
                            <div className="flex flex-col text-left border-l-2 px-2 border-emerald-500">
                                <h1 className="text-emerald-500 text-2xl lg:text-4xl font-semibold">Why InternTracker Was Made</h1>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    I'm Fernando, the creator of InternTracker. I made this website with the sole purpose to help students guarantee themselves an outstanding resume of extracurriculars and to make an impact for everyone.
                                    I wanted to make a difference for people and for myself, as I had never actually done something this large before which helps my community and communities around the nation. My intention is and was to show my interest in computer science and how I can benefit others.
                                </p>
                            </div>
                            <div className="flex flex-col text-left border-l-2 px-2 border-emerald-500">
                                <h1 className="text-emerald-500 text-2xl lg:text-4xl font-semibold">Get To Know Me</h1>
                                <p className="text-gray-300 text-lg lg:text-2xl">
                                    If you want to learn more about me, I have my own portfolio which you can click <a href="https://fernandosoto.xyz" target="_blank" rel="noreferrer" className="text-emerald-500">here.</a> My portfolio shows my background of what I do, projects I've made and general information about myself.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </main>
    );
}
