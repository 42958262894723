import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';

export default function Login() {
  const [name, setName] = useState('');
  const [key, setKey] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    const success = login(name, key);
    if (success) {
      navigate('/admin');
    }
  };

  return (
    <main className="flex flex-col font-outfit min-h-screen bg-neutral-900">
      <div className="bg-neutral-900">
        <section id="login" className="w-screen h-screen flex flex-col items-center justify-center bg-neutral-900">
          <div className="bg-zinc-800 border-[1px] border-zinc-700 w-[30rem] h-[15rem] flex flex-col items-center justify-evenly rounded-xl">
            <input
              required
              placeholder="Name"
              className="h-12 w-56 rounded p-2 outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              required
              placeholder="Key"
              type="password"
              className="h-12 w-56 rounded p-2 outline-none"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
            <button
              className="w-40 h-10 bg-emerald-800 rounded-lg shadow-xl font-light border-[1px] border-emerald-700 outline-none text-white"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </section>
      </div>
    </main>
  );
}
