import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase.js';

const AddInternship = () => {
    const [company, setCompany] = useState('');
    const [desc, setDesc] = useState('');
    const [field, setField] = useState('');
    const [city, setCity] = useState('');
    const [number, setNumber] = useState('');
    const [website, setWebsite] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'internships'), { //Adds document to internship collection
                company, 
                desc, 
                field, 
                city, 
                number
            });

            setCompany('');
            setDesc('');
            setField('');
            setCity('');
            setNumber('');
            setWebsite('');
        } catch(error) {
            console.error("Error loading document: ", error);
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col p-4 bg-zinc-800 border-[1px] border-zinc-700 rounded-xl w-[30rem] h-auto">
            <input 
                type="text" 
                value={company} 
                onChange={(e) => setCompany(e.target.value)} 
                placeholder="Company" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={desc} 
                onChange={(e) => setDesc(e.target.value)} 
                placeholder="Description" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={field} 
                onChange={(e) => setField(e.target.value)} 
                placeholder="Category" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={city} 
                onChange={(e) => setCity(e.target.value)} 
                placeholder="Location" 
                required 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={number} 
                onChange={(e) => setNumber(e.target.value)} 
                placeholder="Contact" 
                className="mb-2 p-2 rounded"
            />
            <input 
                type="text" 
                value={website} 
                onChange={(e) => setWebsite(e.target.value)} 
                placeholder="Website"  
                className="mb-2 p-2 rounded"
            />
            <button type="submit" className="bg-emerald-600 text-white p-2 rounded">Add Internship</button>
        </form>
    );
};

export default AddInternship;